import { toast } from "react-toastify";
import { API_ROOT_URL, USER_ROLES } from "../../constants";
import getS3DownloadLink from "../../../utilis/getS3signedURL";
import triggerFileDownload from "../../../utilis/triggerFileDownload";

const downloadInduction = async (inductionId, token, setLoading, abortSignal, userRole) => {
    if (setLoading) { setLoading(prevState => ({ ...prevState, [inductionId]: { loading: true, aborting: false } })) };
    try {

        const toastInfoId = userRole === USER_ROLES.SUPER_ADMIN || userRole === USER_ROLES.COMPANY ? null : toast.info("Download has been Started! Once Completed, you will be informed", {
            autoClose: true,
        });
        
        const response = await fetch(`${API_ROOT_URL}/induction/download/${inductionId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/pdf",
                "x-access-token": token
            },
            signal: abortSignal
        });

        if (!response.ok) {
            throw new Error("failed to download induction");
        }

        const data = await response.json();

        const fileUrl = getS3DownloadLink(data.fileName);

        // download file
        triggerFileDownload(fileUrl, data.fileName);

        toast.dismiss(toastInfoId)
        toast.success("Download successful!");
    } catch (error) {
        toast.dismiss();
        if (error.name === "AbortError") {
            toast.error("Download was cancelled.");
        } else {
            toast.error("Download failed!");
            console.error(error.message);
        }
    } finally {
        if (setLoading) { setLoading(prevState => ({ ...prevState, [inductionId]: false })) };
    }
};

export default downloadInduction;
