import jwtDecode from "jwt-decode";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import Swal from "sweetalert2";
import "swiper/css";
import oopsemptybox from "../Animations/animation_lkieb38t.json";
import course1 from "./../../../images/courses/course1.jpg";
import InductionList from "./InductionList";
//dropdowns
import GetDepartmentByComp from "../Department/GetDeptByCompany";
import TopicDropdown from "../Topics/TopicDropdown";
import { Skeleton } from 'primereact/skeleton';
//api root url
import { API_ROOT_URL, AWS_BUCKET_NAME, INDUCTION_ASSETS_URL } from "../../constants";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MdOutlineArrowUpward } from "react-icons/md";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import s3 from "../../../utilis/aws.config";
import downloadInduction from "./DownloadInduction";
import AbortControllerService from "../../../utilis/abortControllerService";
import CustomDropdown from "./CustromDropdown";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  DEPARTMENT: "department",
};

const queryStyle = {
  height: 300,
};

const endmessage = {
  height: 40,
};


function Inductions(props) {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.auth.auth.role);
  const token = useSelector((state) => state.auth.auth.token);
  const id = useSelector((state) => state.auth.auth.id);
  const decoded = jwtDecode(token);
  const currentDeptID = decoded.deptID;
  const [inductions, setInductions] = useState([]);
  const [source, setSource] = useState("list");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  const [hasError, setHasError] = useState(false);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);

  const [compid, setcompID] = useState();
  const [filterCompany, setFilterCompany] = useState();
  const [filterDept, setFilterDept] = useState();
  const [filterTopic, setFilterTopic] = useState();
  const [filterSubTopic, setFilterSubTopic] = useState();
  const [topicID, setTopicID] = useState();
  const [subTopicSelectedID, setSubTopicSelectedID] = useState("");
  const [subTopicList, setSubTopicList] = useState([]);
  const [isCompDeleted, setIsCompDeleted] = useState(false);
  const [selectedSubTopics, setSelectedSubTopics] = useState([]);

  const tokenDetailsString = localStorage.getItem('userDetails');

  async function getAllInductions(page, token) {
    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    }
    else {
      return fetch(`${API_ROOT_URL}/induction?page=${page}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }).then((data) => data.json());
    }
  }

  const confirmHandler = (inductionID) => {
    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      // user id
      swal({
        title: "Are you sure?",
        text: `By clicking OK, You will be redirectd to the Induction.`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          swal("Poof! Your induction has been started!", {
            icon: "success",
          }).then(() => {
            if (USER_ROLES.USER === userRole) {
              navigate.push(`/view-induction/${inductionID}`);
            } else {
              navigate.push(`/single-induction-view/${inductionID}`);
            }
          });
        } else {
          return swal("Failed", "Induction is not started", "error");
        }
      });
    }
  };
  //function for denied permission of cross departments
  const permissionDenied = () => {
    // user id
    swal({
      title: "Sorry !",
      text: `You cannot edit this induction.`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(() => {
      navigate.push(`/inductions`);
    });
  };

  //Function to get all topics
  const handleTopicChange = async (e) => {
    setSubTopicList([])
    setSubTopicSelectedID(e.target.value);
    const response = await getSubtopicsMultiDropdown(filterTopic);
    if ("status" in response && response.status === true) {
      setSubTopicList(response.data);
      setLoading(false);
    }
  };

  // SUbtopics MultiSelect Dropdown
  async function getSubtopicsMultiDropdown(topicID) {
    return fetch(
      `${API_ROOT_URL}/department/fetching/subtopics/multidropdown/by/topics/${topicID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    ).then((data) => data.json());
  }

  // api for filter inductions by department
  async function filterInductionbyDepartment(page, companyID, deptId) {
    let filterInductionApidept = `${API_ROOT_URL}/induction/filter/by/company/department?page=${page}&filterByCompany=${companyID}&filterByDepartment=${deptId}`;
    if (deptId === "all") {
      if (userRole === "company") {
        filterInductionApidept = `${API_ROOT_URL}/induction/filter/by/company?page=${page}&filterByCompany=${id}`;
      } else if (deptId === "all" && companyID === "all") {
        filterInductionApidept = `${API_ROOT_URL}/induction?page=${page}`;
      } else if (userRole === "instructor") {
        filterInductionApidept = `${API_ROOT_URL}/induction?page=${page}`;
      } else if (userRole === "department") {
        filterInductionApidept = `${API_ROOT_URL}/induction?page=${page}`;
      } else {
        filterInductionApidept = `${API_ROOT_URL}/induction/filter/by/company?page=${page}&filterByCompany=${companyID}`;
      }
    }
    return fetch(filterInductionApidept, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  // api for filter inductions by Topics
  async function filterInductionbyTopic(page, topicID, deptID) {
    setTopicID(topicID);
    let filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/by/topic?page=${page}&topicID=${topicID}`;
    if (topicID === "all" && deptID && deptID !== "all") {
      filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/by/company/department?page=${page}&filterByCompany=&filterByDepartment=${deptID}`;
    } else if (topicID === "all") {
      filterInductionApiTopic = `${API_ROOT_URL}/induction?page=${page}`;
    }

    return fetch(filterInductionApiTopic, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  // api for filter inductions by Topics
  async function filterInductionbySubTopic(page, topicID, subtopicIDs) {
    var subtopicIDArray = subtopicIDs.map((subtopicID) => subtopicID.value);
    let filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/by/topic/subtopics?page=${page}`;
    if (topicID && subtopicIDArray.length === 0) {
      return fetch(
        `${API_ROOT_URL}/induction/filter/by/topic?page=${page}&topicID=${topicID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      ).then((data) => data.json());
    } else if (subtopicIDs === "all") {
      return fetch(
        `${API_ROOT_URL}/induction/filter/by/topic?page=${page}&topicID=${topicID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      ).then((data) => data.json());
    }
    if (subtopicIDArray.length > 0) {
      return fetch(filterInductionApiTopic, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify({ subtopicID: subtopicIDArray }),
      }).then((data) => data.json());
    }
  }

  useEffect(() => {
    // Initial data fetching when the component mounts

    const fetchData = async () => {
      setLoading(true);

      const response = await getAllInductions(1, token);

      if ("status" in response && response.status === true) {
        setInductions(response.data);
        setCurrentPage(1);
        setHasMoreData(response.pagination.hasNextPage);
        setTotalRecords(response.pagination.totalRecords);
        setHasError(false);
        setLoading(false);
      } else {
        setTotalRecords(0);
        setHasError(true);
        setLoading(false);
      }
    };
    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      fetchData();
    }
  }, []);

  const loadMoreData = async () => {
    if (!hasMoreData) return;

    setLoadingMore(true)
    const nextPage = currentPage + 1;
    try {
      let response;

      if (source === "filerDept") {
        response = await filterInductionbyDepartment(
          nextPage,
          compid,
          filterDept
        );
      } else if (source === "filterByTopic") {
        response = await filterInductionbyTopic(nextPage, topicID, filterDept);
      } else if (source === "filterBySubTopic") {
        response = await filterInductionbySubTopic(
          nextPage,
          subTopicSelectedID,
          filterSubTopic
        );
      } else {
        response = await getAllInductions(nextPage, token);
      }

      if ("status" in response && response.status === true) {
        setInductions((prevData) => [...prevData, ...response.data]);
        setCurrentPage(nextPage);
        setHasMoreData(response.pagination.hasNextPage);
        setTotalRecords(response.pagination.totalRecords);
      }
    } catch (err) {
      setError(err);
    }
    setLoadingMore(false);
  };

  const filterByDepartment = (deptID) => {
    setSelectedSubTopics([]);
    setSubTopicList([]);
    const handlepageLoad = async (event) => {
      setSource("filerDept");
      setFilterDept(deptID);
      setLoading(true);
      setSelectedSubTopics([]);
      const response = await filterInductionbyDepartment(1, compid, deptID);
      if ("status" in response && response.status === true) {
        setInductions(response.data);
        setCurrentPage(1); // Reset current page to 1
        setHasMoreData(response.pagination.hasNextPage);
        setTotalRecords(response.pagination.totalRecords);
        setHasError(false);
      } else {
        setTotalRecords(0);
        setHasError(true);
      }
      setLoading(false);
    };
    handlepageLoad();
  };

  //filter by topic
  const filterByTopic = (topicID) => {
    const handlepageLoad = async (event) => {
      setSource("filterByTopic");
      setFilterTopic(topicID);
      setLoading(true);
      setSelectedSubTopics([]);
      setSubTopicList([]);
      const response = await filterInductionbyTopic(1, topicID, filterDept);
      if ("status" in response && response.status === true) {
        setInductions(response.data);
        setCurrentPage(1); // Reset current page to 1
        setHasMoreData(response.pagination.hasNextPage);
        setTotalRecords(response.pagination.totalRecords);
        setHasError(false);
      } else {
        setTotalRecords(0);
        setHasError(true);
      }
      setLoading(false);
    };
    handlepageLoad();
  };

  const filterBySubTopic = (subtopicID) => {
    setSelectedSubTopics(subtopicID)
    const handlepageLoad = async (event) => {
      setSource("filterBySubTopic");
      setFilterSubTopic(subtopicID);
      setLoading(true);
      const response = await filterInductionbySubTopic(1, topicID, subtopicID);
      if ("status" in response && response.status === true) {
        setInductions(response.data);
        setCurrentPage(1); // Reset current page to 1
        setHasMoreData(response.pagination.hasNextPage);
        setTotalRecords(response.pagination.totalRecords);
        setHasError(false);
      } else {
        setTotalRecords(0);
        setHasError(true);
      }
      setLoading(false);
    };
    handlepageLoad();
  };

  useEffect(() => {
    if (isCompDeleted) {
      const fetchData = async () => {
        try {
          const updatedData = await getAllInductions(1, token);
          setInductions(updatedData.data);
          setTotalRecords(updatedData.pagination.totalRecords);
        } catch (err) {
          setError(err);
        }
      };
      fetchData();
      loadMoreData();
    }
  }, [isCompDeleted]);

  //delete click
  const trackDeleteClick = (inductionId) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this record ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willChange) => {
      if (willChange) {
        const response = await fetch(
          `${API_ROOT_URL}/induction/soft/delete/induction`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({ inductionId: inductionId }),
          }
        ).then((data) => data.json());
        if ("status" in response && response.status === true) {
          swal("Poof! Your record has been deleted!", {
            icon: "success",
          }).then(() => {
            setIsCompDeleted(!isCompDeleted);
          });
        } else {
          return swal("Failed", response.message, "error");
        }
      } else {
        swal("Your record is safe!");
      }
    });
  };

  //sweetalert message for private induction
  const handleClick = (recordName, inductionID) => {
    Swal.fire({
      title: `${recordName}`,
      text: "Do you want to Request Access for this Induction ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#58bad7",
      cancelButtonColor: "#3a3630",
      confirmButtonText: "Yes",
    }).then(async (willChange) => {
      if (willChange.isConfirmed) {
        const response = await fetch(
          `${API_ROOT_URL}/induction/request/access/for/private/induction`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({
              inductionID: inductionID,
              deptID: currentDeptID,
            }),
          }
        ).then((data) => data.json());
        if ("status" in response && response.status === true) {
          Swal.fire({
            title: "Request Sent",
            text: `Waiting for confirmation of ${recordName}`,
            icon: "success",
            confirmButtonColor: "#58bad7",
          });
        } else {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.message,
          });
        }
      }
    });
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const getSignedUrl = (fileName) => {
    return s3.getSignedUrl("getObject", {
      Bucket: AWS_BUCKET_NAME,
      Key: fileName,
    });
  };

  const handleDownload = (inductionId) => {
    const controller = AbortControllerService.create();
    downloadInduction(inductionId, token, null, controller.signal, userRole);
  };

  //skeleton component to re-render multiple times
  const InductionSkeletonComponent = () => {

    return (
      <div className="col-xl-4 col-md-6 cardDiv" >
        <div className="all-crs-wid inductiondiv animatedInduction card profile-widget">
          <div className="card-body fadeInAnimation">
            <div className="border-round border-1 surface-border p-4 surface-card">
              <div className="flex mb-3">
                <div className="d-flex justify-content-end align-items-end">
                  <Skeleton className="mb-3" width="4rem" height="1.5rem"></Skeleton>
                </div>
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="mt-3">
                  <Skeleton height=".5rem"></Skeleton>
                </div>
              </div>

              <div className="flex justify-content-between mt-3">
                <Skeleton width="10rem" height=".5rem" className="mb-2"></Skeleton>
              </div>
              <div className="row">
                <div className="col-xl-8 d-flex justify-content-start align-items-start">
                  <Skeleton className="mt-3" width="4rem" height="1.5rem"></Skeleton>
                </div>
                <div className="col-xl-4 d-flex justify-content-end align-items-end">
                  <Skeleton shape="circle" width="4rem" height="2rem" borderRadius="2rem"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      {USER_ROLES.SUPER_ADMIN === userRole ||
        USER_ROLES.COMPANY === userRole ? (
        <InductionList props={props} />
      ) : null}
      {USER_ROLES.INSTRUCTOR === userRole ||
        USER_ROLES.USER === userRole ||
        USER_ROLES.DEPARTMENT === userRole ? (
        <>
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-header flex-wrap p-3">
                <h3 className="mt-0">All Inductions ({totalRecords})</h3>
                {USER_ROLES.DEPARTMENT === userRole ||
                  USER_ROLES.INSTRUCTOR === userRole ? (
                  <>
                    <Row>
                      <Col lg="4" className="mb-1">
                        <select
                          name="deptID"
                          style={{ borderRadius: "4px", backgroundColor: "white" }}
                          className="select btn"
                          onChange={(e) => filterByDepartment(e.target.value)}
                        >
                          <option value="all">DEPARTMENT</option>
                          <GetDepartmentByComp parentCompany={id} />
                        </select>
                      </Col>
                      <Col lg="4" className="mb-1">
                        <select
                          name="topicID"
                          style={{ borderRadius: "4px", backgroundColor: "white" }}
                          className="select btn"
                          onChange={(e) => filterByTopic(e.target.value)}
                          onClick={handleTopicChange}
                        >
                          <option value="all">TOPIC</option>
                          <TopicDropdown deptID={filterDept} />
                        </select>
                      </Col>
                      <Col lg="4" className="mb-1">
                        <Select
                          options={subTopicList || []}
                          className="fw-900 fs-14"
                          placeholder="SUBTOPIC"
                          onChange={filterBySubTopic}
                          isSearchable={true}
                          isMulti
                          styles={{ width: "100%" }}
                          value={selectedSubTopics}
                          noOptionsMessage={() => "NO SUBTOPICS"}
                        />
                      </Col>
                    </Row>
                  </>
                ) : null}
                {USER_ROLES.USER === userRole ? (
                  <>
                    <div className="row">
                      <div className="btn-group" style={{ display: "flex", gap: "20px" }}>
                        <div className="btn-group" style={{ flex: 1 }}>
                          <select
                            name="topicID"
                            style={{ borderRadius: "4px", width: "100%" }}
                            className="btn btn-white col-sm-6 border-light"
                            onChange={(e) => filterByTopic(e.target.value)}
                            onClick={handleTopicChange}
                          >
                            <option value="all">TOPIC</option>
                            <TopicDropdown deptID={decoded.deptID} />
                          </select>
                        </div>

                        <div className="btn-group" style={{ flex: 1 }}>
                          <Select
                            options={subTopicList || []}
                            className="fw-900 fs-14"
                            placeholder="SUBTOPIC"
                            onChange={filterBySubTopic}
                            isSearchable={true}
                            isMulti
                            styles={{ width: "100%" }}
                            value={selectedSubTopics}
                            noOptionsMessage={() => "NO SUBTOPICS"}
                          />
                        </div>
                      </div>
                    </div>

                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div
            className="row dataTables_wrapper no-footer"
            id="student_wrapper"
          >
            {hasError ? (
              <div className="h-100">
                <div className="container h-100">
                  {" "}
                  <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-5">
                      <div className="form-input-content text-center">
                        <Lottie
                          style={queryStyle}
                          animationData={oopsemptybox}
                        />
                        <h2
                          style={{
                            color: "#3a3630",
                            letterSpacing: "1px",
                          }}
                        >
                          INDUCTIONS NOT FOUND !
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {
                  loading ?
                    <>
                      {Array(6).fill(<InductionSkeletonComponent />)}
                    </>
                    :
                    <>
                      {inductions.map((data, index) => (
                        <div className="col-xl-4 col-md-6 cardDiv" key={index}>
                          <div className="all-crs-wid inductiondiv animatedInduction card profile-widget">
                            <div className="card-body fadeInAnimation">
                              <CustomDropdown
                                data={data}
                                decoded={decoded}
                                handleDownload={handleDownload}
                                trackDeleteClick={trackDeleteClick}
                                userRole={userRole}
                                permissionDenied={permissionDenied}
                              />
                              <div className="courses-bx">
                                <div className="dlab-media">
                                  {data.thumbnail !== "" ? (
                                    <LazyLoadImage
                                      src={getSignedUrl(`${INDUCTION_ASSETS_URL}/${data.thumbnail}`)}
                                      alt={data.thumbnail}
                                      loading="lazy"
                                    />
                                  ) : (
                                    <img className="img-fluid" src={course1} />
                                  )}
                                </div>
                                <div className="dlab-info">
                                  <div className="dlab-title">
                                    <div>
                                      <div>
                                        <span className="d-flex justify-content-between content align-items-center">
                                          <h4>
                                            <Link
                                              to={`/single-induction-view/${data._id}`}
                                            >
                                              {data.title}
                                            </Link>
                                          </h4>
                                          <div
                                            className={`custombadge ${data?.isPrivate === true
                                              ? "custombadge-red"
                                              : "custombadge-green"
                                              }`}
                                          >
                                            {data?.isPrivate === true
                                              ? "PRIVATE"
                                              : "PUBLIC"}
                                          </div>
                                        </span>
                                      </div>
                                      <p className="m-0">
                                        {data.subTitle}

                                        <svg
                                          className="ms-1"
                                          width="4"
                                          height="5"
                                          viewBox="0 0 4 5"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="2"
                                            cy="2.5"
                                            r="2"
                                            fill="#DBDBDB"
                                          />
                                        </svg>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between content align-items-center">
                                    <span>
                                      <svg
                                        className="me-2"
                                        width="24"
                                        height="25"
                                        viewBox="0 0 24 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M21.2 18.6C20.5 18.5 19.8 18.4 19 18.4C16.5 18.4 14.1 19.1 12 20.5C9.90004 19.2 7.50005 18.4 5.00005 18.4C4.30005 18.4 3.50005 18.5 2.80005 18.6C2.30005 18.7 1.90005 19.2 2.00005 19.8C2.10005 20.4 2.60005 20.7 3.20005 20.6C3.80005 20.5 4.40005 20.4 5.00005 20.4C7.30005 20.4 9.50004 21.1 11.4 22.5C11.7 22.8 12.2 22.8 12.6 22.5C15 20.8 18 20.1 20.8 20.6C21.3 20.7 21.9 20.3 22 19.8C22.1 19.2 21.7 18.7 21.2 18.6ZM21.2 2.59999C20.5 2.49999 19.8 2.39999 19 2.39999C16.5 2.39999 14.1 3.09999 12 4.49999C9.90004 3.09999 7.50005 2.39999 5.00005 2.39999C4.30005 2.39999 3.50005 2.49999 2.80005 2.59999C2.40005 2.59999 2.00005 3.09999 2.00005 3.49999V15.5C2.00005 16.1 2.40005 16.5 3.00005 16.5C3.10005 16.5 3.10005 16.5 3.20005 16.5C3.80005 16.4 4.40005 16.3 5.00005 16.3C7.30005 16.3 9.50004 17 11.4 18.4C11.7 18.7 12.2 18.7 12.6 18.4C15 16.7 18 16 20.8 16.5C21.3 16.6 21.9 16.2 22 15.7C22 15.6 22 15.6 22 15.5V3.49999C22 3.09999 21.6 2.59999 21.2 2.59999Z"
                                          fill="#c7c7c7"
                                        />
                                      </svg>
                                      {data.numOfSlides} Slides
                                    </span>

                                    {USER_ROLES.USER === userRole ? (
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        style={{
                                          borderRadius: "66px",
                                          padding: "10px 25px 10px 25px",
                                        }}
                                        onClick={(e) => confirmHandler(`${data._id}`)}
                                      >
                                        View
                                      </button>
                                    ) : (
                                      <>
                                        {data.deptID === currentDeptID ? (
                                          <>
                                            <Link
                                              to={`/single-induction-view/${data._id}`}
                                              className="btn btn-primary"
                                              style={{
                                                borderRadius: "66px",
                                                padding: "10px 25px 10px 25px",
                                              }}
                                            >
                                              View
                                            </Link>
                                          </>
                                        ) : (
                                          <>
                                            {data.isPrivate === true ? (
                                              <>
                                                <>
                                                  {data.privateInductionAccessTo !==
                                                    undefined &&
                                                    data.privateInductionAccessTo.includes(
                                                      currentDeptID
                                                    ) ? (
                                                    <>
                                                      <Link
                                                        to={`/single-induction-view/${data._id}`}
                                                        className="btn btn-primary"
                                                        style={{
                                                          borderRadius: "66px",
                                                          padding:
                                                            "10px 25px 10px 25px",
                                                        }}
                                                      >
                                                        View
                                                      </Link>
                                                    </>
                                                  ) : (
                                                    <Link
                                                      onClick={(e) =>
                                                        handleClick(
                                                          data?.title,
                                                          data._id
                                                        )
                                                      }
                                                      className="btn btn-primary"
                                                      style={{
                                                        borderRadius: "66px",
                                                        padding:
                                                          "10px 25px 10px 25px",
                                                      }}
                                                    >
                                                      View
                                                    </Link>
                                                  )}
                                                </>
                                              </>
                                            ) : (
                                              <Link
                                                to={`/single-induction-view/${data._id}`}
                                                className="btn btn-primary"
                                                style={{
                                                  borderRadius: "66px",
                                                  padding: "10px 25px 10px 25px",
                                                }}
                                              >
                                                View
                                              </Link>
                                            )}{" "}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      ))}
                    </>
                }
              </>
            )}
            {loadingMore ? <>
              {Array(3).fill(<InductionSkeletonComponent />)}
            </> : null}
          </div>

          {
            inductions.length > 6 ?
              <>
                <button
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                  style={{
                    padding: '10px 20px',
                    fontSize: '13px',
                    bottom: '10px',
                    right: '10x',
                    backgroundColor: '#3a3630',
                    border: "none",
                    color: '#fff',
                    textAlign: 'center',
                    borderRadius: "66px",
                    display: "flex",
                    float: "right"
                  }}
                >
                  Scroll to top {" "} {" "} <MdOutlineArrowUpward className="mx-1" size={18} />
                </button>
              </>
              : null
          }
        </>
      ) : null}
      {USER_ROLES.DEPARTMENT === userRole ||
        USER_ROLES.INSTRUCTOR === userRole ||
        USER_ROLES.USER === userRole ? (
        <InfiniteScroll
          dataLength={inductions.length}
          next={loadMoreData}
          hasMore={hasMoreData}
          scrollThreshold={1}
          loader={inductions.length > 0 ? <span> <Lottie
          // style={oopStyle}
          // animationData={oops}
          /></span> : null}
        ></InfiniteScroll>
      ) : null}
    </>
  );
}

export default Inductions;
